<template>
  <v-footer id="home-footer" color="orange" dark min-height="72">
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <div
            class="d-flex flex-wrap justify-md-start justify-center justify-md-none"
          >
            <template >
              <a

                class="white--text pa-1 pa-md-0"
                href="https://www.facebook.com/Orenda-Financial-Services-103154957757499/"
                target="_blank"
              >Facebook</a>

              <v-responsive
                class="mx-4 shrink hidden-sm-and-down"
                max-height="24"
              >
                <v-divider vertical />
              </v-responsive>

              <a
                class="white--text pa-1 pa-md-0"
                href="https://instagram.com/orendafinancialservice1?igshid=18n10y503y9ko"
                target="_blank"
              >Instagram</a>

            </template>
          </div>
        </v-col>

        <v-col class="text-center text-md-right" cols="12" md="6">
          Copyright &copy; 2021 Orenda
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "HomeFooter",

  data: () => ({
    social: ["Facebook", "Instagram"],
  }),
};
</script>

<style lang="sass">
#home-footer a
  text-decoration: none
</style>
